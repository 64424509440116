import logo from './logo.svg';
import './App.css';

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Avatar from '@mui/material/Avatar'
import { useTheme } from '@mui/material/styles'

function App() {
  const theme = useTheme();

  const avatarSize = 100;

  return <Container maxWidth="sm">
    <Grid
      sx={{
        height: '100vh', 
        paddingBottom: theme.spacing(15)
      }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      wrap='nowrap'
    >
      <Grid 
        item 
        sx={{
          marginTop: theme.spacing(3)
        }}
      >
        <Card square variant='outlined'>
          <CardContent>
            <Grid container direction='row' wrap='nowrap' spacing={2}>
              <Grid item>
                <Avatar 
                  src={`${process.env.PUBLIC_URL}/profile_circle.jpg`} 
                  sx={{
                    height: `${avatarSize}px`, 
                    width: `${avatarSize}px`
                  }}
                  alt='Josh McElfresh'
                />
              </Grid>
              <Grid item>
                <Typography variant='h5'>
                  Josh McElfresh
                </Typography>
                <Typography variant='body1' gutterBottom>
                  I'm a software developer living in Portland, OR
                </Typography>
                <Link href="https://www.linkedin.com/in/joshua-mcelfresh-3a952113/" variant='body1'>
                  LinkedIn
                </Link>

              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Container>;
}

export default App;
